<script setup lang="ts">
import { nextTick, onBeforeMount, onMounted, watch } from "vue"
import { useRoute } from "vue-router"
import Header from "@/layouts/components/header/Header.vue"
import Footer from "@/layouts/components/footer/Footer.vue"
import ScrollTop from "@/layouts/components/ScrollTop.vue"
import { reinitializeComponents } from "@/utils/keenthemes"
import { contentWidthFluid } from "@/layouts/config/helper"
import LayoutService from "@/services/LayoutService"

const route = useRoute()

onBeforeMount(() => {
    LayoutService.init()
})

onMounted(() => {
    nextTick(() => {
        reinitializeComponents()
    })
})

watch(
    () => route.path,
    () => {
        nextTick(() => {
            reinitializeComponents()
        })
    }
)

</script>

<template>
    <!-- begin:: Body -->
    <div class="page d-flex flex-column flex-column-fluid">
        <Header/>

        <!-- begin:: Content -->
        <div
            id="kt_content_container"
            class="d-flex flex-column-fluid align-items-stretch"
            :class="{
                'container-fluid': contentWidthFluid,
                'container-xxl': !contentWidthFluid,
            }">
            <div
                id="kt_wrapper"
                class="wrapper d-flex flex-column flex-row-fluid mt-5">
                <div
                    id="kt_content"
                    class="content flex-column-fluid">
                    <!-- begin:: Content Body -->
                    <div
                        id="kt_post"
                        class="post">
                        <slot/>
                    </div>
                    <!-- end:: Content Body -->
                </div>
                <Footer/>
            </div>
            <!-- end:: Content -->
        </div>
    </div>
    <!-- end:: Body -->
    <ScrollTop/>
</template>
